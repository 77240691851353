<template>
  <data-table :data="posts">
    <el-table-column
      label="Scheduled Date"
      prop="scheduled_time"
      sortable
      width="370"
    >
      <template v-if="scope.row.scheduled_time" slot-scope="scope">
        {{ formatInTimeZone(parseISO(scope.row.scheduled_time), timezone, 'ccc MMM d, yyyy @ h:mm a z') }}
        <el-tag
          v-if="!scope.row.ad.posted_through_caas"
          type="info"
          size="small"
        >
          Created by Property
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
      label="Floorplan/Unit"
      width="240"
    >
      <template slot-scope="scope">
        {{ scope.row.ad.floorplan_name }}
        <span v-if="scope.row.ad.floorplan_name && scope.row.ad.unit_name "> / </span>
        {{ scope.row.ad.unit_name }}
      </template>
    </el-table-column>
    <el-table-column
      label="Bed/Bath/sqft"
      width="140"
    >
      <template slot-scope="scope">
        {{ scope.row.ad.content.bedroom_count }}br /
        {{ scope.row.ad.content.bathroom_count }}ba /
        {{ scope.row.ad.content.building_sqft }}
      </template>
    </el-table-column>
    <el-table-column
      label="Title"
      prop="ad.title"
    >
      <template slot-scope="scope">
        <link-button
          v-if="scope.row.ad.view_url"
          :href="scope.row.ad.view_url"
          target="_blank"
          type="primary"
        >
          {{ scope.row.ad.title }}
        </link-button>
        <template v-else>
          {{ scope.row.ad.title }}
        </template>
      </template>
    </el-table-column>
    <el-table-column
      label="Price"
      prop="ad.content.price"
    >
      <template v-if="scope.row.ad.content.price !== null" slot-scope="scope">
        ${{ scope.row.ad.content.price }}
      </template>
    </el-table-column>
    <el-table-column
      label="Account"
      prop="ad.account"
    >
      <template slot-scope="scope">
        {{ scope.row.ad.account }}
      </template>
    </el-table-column>
    <el-table-column
      label="Ad Status"
      prop="status"
      width="120"
      class-name="capitalize"
      sortable
    >
      <template slot-scope="scope">
        <el-tooltip
          :content="scope.row.failure_reason_humanized"
          :disabled="!scope.row.failure_reason_humanized"
        >
          <el-tag
            :type="getStatusTagType(calculateAdStatus(scope.row))"
            size="medium"
          >
            {{ calculateAdStatus(scope.row) }}
          </el-tag>
        </el-tooltip>
      </template>
    </el-table-column>
  </data-table>
</template>

<script>
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import DataTable from '@/components/tables/DataTable'
import LinkButton from '@/components/buttons/LinkButton'

export default {
  name: 'PostingHistoryTable',
  components: {
    DataTable,
    LinkButton
  },
  props: {
    posts: {
      type: Array,
      required: true
    },
    timezone: {
      type: String,
      default: ''
    }
  },
  created () {
    this.formatInTimeZone = formatInTimeZone
    this.parseISO = parseISO
  },
  methods: {
    calculateAdStatus (row) {
      const adStates = ['deleted', 'expired', 'flagged'].includes(row.ad.state)
      if (adStates) {
        return row.ad.state
      }
      if (row.status === 'failed' && row.failure_reason === 'expired') {
        return 'app offline'
      }
      return row.status
    },
    /**
     * Returns the ElementUI tag type given the ad status
     *
     * @param {String} status
     * @returns {String}
     */
    getStatusTagType (status) {
      switch (status) {
        case 'scheduled':
        case 'posting':
          return 'warning'
        case 'pending':
          return ''
        case 'successful':
          return 'success'
        case 'failed':
          return 'danger'
        default: // unscheduled, deleted, expired, flagged, app offline
          return 'info'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .capitalize {
  text-transform: capitalize;
}
.el-tag {
  margin-left: 10px;
}
</style>
