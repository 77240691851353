import { request } from './index'
import { MissingPayloadError } from './errors'

const ProblemReportsAPI = {
  unableToPost: {
    create: data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      return request({
        method: 'post',
        url: '/problem-reports/unable-to-post/',
        data
      })
    }
  }
}
export default ProblemReportsAPI
