<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="Mark as Unable to Post"
    width="450px"
    @close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <el-form-item
        label="Reason"
        prop="reason"
        class="reasons"
      >
        <el-select v-model="formData.reason">
          <el-option
            v-for="option in unableToPostReasons"
            :key="option.value"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label="Comment"
        prop="comment"
      >
        <el-input
          v-model="formData.comment"
          type="textarea"
          :autosize="{ minRows: 5 }"
        />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button type="primary" @click="submit">
        Submit
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { constants } from '@/utils/constants'
export default {
  name: 'IncompleteReasonDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    const commentRequiredReasons = ['Other', 'Content Issue', 'Service Note - No Action Required']
    return {
      commentRequiredReasons,
      formData: {
        reason: '',
        comment: ''
      }
    }
  },
  computed: {
    commentRequired () {
      return this.commentRequiredReasons.includes(this.formData.reason)
    },
    rules () {
      return {
        reason: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        comment: [
          { required: this.commentRequired, message: 'This field is required', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.unableToPostReasons = constants.unableToPostReasons
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        this.$emit('submit', this.formData)
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'cancel' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 0;
  }
}
::v-deep .reasons {
  padding-bottom: 0;
  margin-bottom: 10px;
}
</style>
