<template>
  <div class="data-table">
    <el-row>
      <el-table
        ref="dataTable"
        v-loading="loading"
        v-bind="$attrs"
        :data="data"
        stripe
        border
        @selection-change="handleSelection"
        @row-click="$emit('row-click', $event)"
      >
        <!-- render table columns -->
        <slot />
      </el-table>
    </el-row>
  </div>
</template>

<script>
/**
 * Wrapper for ElementUI table with custom table styling.
 *
 * Supports all element table attributes:
 * https://element.eleme.io/#/en-US/component/table#table-attributes
 */
export default {
  name: 'DataTable',
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    selected: { // required for multi select functionality
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isShift: false
    }
  },
  created () {
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  },
  methods: {
    handleKeyDown (event) {
      if (event.keyCode === 16) {
        this.isShift = true
      }
    },
    handleKeyUp (event) {
      if (event.keyCode === 16) {
        this.isShift = false
      }
    },
    handleSelection (selection) {
      this.$emit('selection-change', selection)
      if (this.isShift && this.selected.length) {
        if (selection.length > this.selected.length) { // select
          const newSelectedIndex = this.data.findIndex(item => item === selection[selection.length - 1])
          const lastSelectedIndex = this.data.findIndex(item => item === this.selected[this.selected.length - 1])
          const min = Math.min(newSelectedIndex, lastSelectedIndex)
          const max = Math.max(newSelectedIndex, lastSelectedIndex)
          for (let i = min + 1; i < max; i++) {
            // also select rows in between
            this.$refs.dataTable.toggleRowSelection(this.data[i], true)
          }
        }
      }
    }
  }
}
</script>

<style>
.data-table .el-table th, .data-table .el-table td {
  padding: 3px 0;
}
.data-table .el-table th {
  background-color: #ebeef5;
}
</style>

<style scoped>
.data-table {
  margin-top: 1em;
}
</style>
