/**
 * Utility functions around Rooof product and feature subscriptions.
 * Due to the flexible nature of subscriptions and statuses, there is no definition
 * of what it means for a property to 'have' a subscription to something.
 * We will want to handle this on a case by case basis.
 */
import { enums } from '@/utils/constants'

/**
 * Given a property, return features from product subscriptions and feature subscriptions.
 *
 * @param {Object} property - Rooof property
 * @param {Function} statusFilter - Function to filter subscriptions by status
 * @returns {Object}
*/
export function getFeatureSet (property, statusFilter = () => true) {
  const features = new Set()
  for (const feature of property.features_list) {
    if (!statusFilter(feature.status)) {
      continue
    }
    features.add(feature.name)
  }
  return features
}

/**
 * Returns a property's product subscriptions.
 * @param {Object} property - Rooof property
 * @param {Function} statusFilter - Function to filter subscriptions by status
 * @returns {Array}
 */
export function getProductSubscriptions (property, statusFilter = () => true) {
  return property.product_subscriptions.filter(subscription => statusFilter(subscription.status))
}

/**
 * Returns a property's feature subscriptions.
 * @param {Object} property - Rooof property
 * @param {Function} statusFilter - Function to filter subscriptions by status
 * @returns {Array}
 */
export function getFeatureSubscriptions (property, statusFilter = () => true) {
  return property.feature_subscriptions.filter(subscription => statusFilter(subscription.status))
}

/**
 * Returns an appended string of all product and feature subscriptions given a property.
 * @param {Object} property - Rooof property
 * @param {Function} statusFilter - Function to filter subscriptions by status
 * @returns {String} - e.g. "PostEngine Enterprise, Landing Pages, Lead Tracking (email)"
 */
export function getSubscriptionsString (property, statusFilter) {
  const subscriptions = [
    ...getProductSubscriptions(property, statusFilter).map(subscription => `${subscription.product} [${subscription.status}]`),
    ...getFeatureSubscriptions(property, statusFilter).map(subscription => `${subscription.feature} [${subscription.status}]`)
  ]
  return subscriptions.join(', ')
}

/**
 * Get discount value in dollars for a subscription
 *
 * @param {Object} subscription
 * @returns {Number}
 */
export function getDiscountValue (subscription) {
  switch (subscription.discount_type) {
    case '$':
      return parseFloat(subscription.discount)
    case '%':
      return parseFloat(subscription.rate) * (subscription.discount / 100)
  }
}

/**
 * Returns true if the given subscription has an active discount.
 *
 * @param {Object} subscription
 * @returns {Boolean}
 */
export function hasDiscount (subscription) {
  if (subscription.discount && subscription.discount_expires) {
    return subscription.discount_expires >= new Date()
  }
  return Boolean(subscription.discount)
}

/**
 * Calculate the total (rate - discount), of a subscription
 *
 * @param {Object} subscription
 * @returns {String} - total value of the subscription
 */
export function getTotalValue (subscription) {
  let total = parseFloat(subscription.rate)
  let discount = parseFloat(subscription.discount)

  if (hasDiscount(subscription)) {
    switch (subscription.discount_type) {
      case '$': {
        total -= discount
        break
      }
      case '%': {
        discount = subscription.rate * (discount / 100)
        total -= discount
        break
      }
    }
  }
  return total ? `${total.toFixed(2)}` : '0.00'
}

/**
 * Calculate the total amount the property is billed for each month
 *
 * @param {Object} property
 * @returns {String} - total value of the subscription, rounded to two decimals
 */
export function getTotalMonthlyBillingRate (property) {
  const statusFilter = (status) => status === enums.status.ACTIVE
  const subscriptions = [
    ...getProductSubscriptions(property, statusFilter),
    ...getFeatureSubscriptions(property, statusFilter)
  ]
  const monthlyTotal = subscriptions.reduce(
    (accumulator, subscription) => accumulator + parseFloat(getTotalValue(subscription)), 0
  )
  return monthlyTotal.toFixed(2)
}
