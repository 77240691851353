<template>
  <el-row type="flex" class="widget-container">
    <div class="widget">
      <div v-if="data.taskIsCompleted" class="widget-icon widget-icon--success">
        <check-circle-icon />
      </div>
      <div v-else-if="data.taskIsUnableToComplete" class="widget-icon widget-icon--warning">
        <warning-icon />
      </div>
      <div v-else class="widget-icon">
        <i class="el-icon-circle-close" />
      </div>
      <span class="label">
        <div v-if="data.taskIsCompleted">
          Posting Task Completed!
        </div>
        <div v-else-if="data.taskIsUnableToComplete">
          Unable to Post
          <div v-if="data.lastUnableToPostDate" class="caption">
            [{{ data.lastUnableToPostDate }}] {{ data.lastUnableToPostReason }}
          </div>
        </div>
        <div v-else>
          Posting Task Incomplete
        </div>
        <div v-if="data.lastCompletedDate" class="caption">
          Last completed date: {{ data.lastCompletedDate }}
        </div>
        <div class="caption">
          # of posts queued: {{ data.queuedPostsCount }}
        </div>
        <el-button
          v-if="!data.taskIsCompleted && !data.taskIsUnableToComplete"
          type="success"
          size="mini"
          @click="$emit('mark-complete')"
        >
          Mark as Completed
        </el-button>
        <el-button
          v-if="!data.taskIsUnableToComplete && !data.taskIsCompleted"
          size="mini"
          @click="$emit('mark-unable')"
        >
          Mark as Unable to Post
        </el-button>
        <el-button
          v-if="data.taskIsCompleted || data.taskIsUnableToComplete"
          size="mini"
          @click="$emit('mark-incomplete')"
        >
          Mark as Incomplete
        </el-button>
      </span>
    </div>
    <div class="widget">
      <div class="widget-icon widget-icon--primary">
        <i class="el-icon-date" />
      </div>
      <div class="label">
        <div>
          {{ data.futurePostsCount }} Posts scheduled for the next 7 days
        </div>
        <div class="caption">
          <span v-for="(day, index) in dailyPostsBreakdown.keys()" :key="day">
            {{ day }}:
            <span :class="{'warning' : dailyPostsBreakdown.get(day) < data.postingTarget }">
              {{ dailyPostsBreakdown.get(day) }}
            </span>
            <span v-if="index < dailyPostsBreakdown.size - 1">| </span>
          </span>
        </div>
        <div class="caption">
          Daily Posting Target: {{ data.postingTarget }}
        </div>
        <el-button
          v-if="data.unitUrl"
          type="primary"
          size="mini"
          @click="openUrl(data.unitUrl)"
        >
          Schedule an Ad
        </el-button>
        <el-button
          v-else-if="data.floorPlanUrl"
          type="primary"
          size="mini"
          @click="openUrl(data.floorPlanUrl)"
        >
          Schedule an Ad
        </el-button>
      </div>
    </div>
    <div class="widget">
      <div class="widget-icon widget-icon--primary">
        <warning-icon />
      </div>
      <span class="label">{{ data.numberOfFailures }} Failures in the past 48 hours</span>
    </div>
  </el-row>
</template>

<script>
import CheckCircleIcon from '@/assets/icons/svg/check_circle.svg'
import WarningIcon from '@/assets/icons/svg/exclamation_triangle.svg'

export default {
  name: 'FullServicePostsWidgets',
  components: {
    CheckCircleIcon,
    WarningIcon
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    dailyPostsBreakdown () {
      const days = this.data.dailyPostsBreakdown
      const daysMap = new Map([
        ['Mon', days[1]],
        ['Tue', days[2]],
        ['Wed', days[3]],
        ['Thurs', days[4]],
        ['Fri', days[5]]
      ])
      if (this.data.isPremium) {
        daysMap.set('Sat', days[6])
        daysMap.set('Sun', days[0])
      }
      return daysMap
    }
  },
  methods: {
    /**
     * Sends a message to the chrome extension
     * with the property id which then opens a new tab
     * to the floorplans page.
     *
     * @param {String} url
     */
    openUrl (url) {
      window.postMessage({
        name: 'OpenCaaSTab',
        url: url,
        property_filter: this.data.propertyId
      }, '*')
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #1a7dc6;
$success: #67c23a;
$warning: #e6a23c;
$text-regular: #606266;
$border-color-base: #dcdfe6;
.widget-container {
    flex-wrap: wrap;

  .widget {
    min-width: 300px;
    padding: 1em;
    margin: 1em;
    margin-left: 0;
    border: 1px solid $border-color-base;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .widget-icon {
      width: 100px;
      height: 100px;
      background-color: rgba($text-regular, 0.2);
      color: $text-regular;
      border-radius: 12px;
      font-size: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
        fill: $text-regular;
      }

      &.widget-icon--primary {
        color: $primary;
        background-color: rgba($primary, 0.2);
        svg {
          fill: $primary;
        }
      }

      &.widget-icon--success {
        color: $success;
        background-color: rgba($success, 0.2);
        svg {
          fill: $success;
        }
      }

      &.widget-icon--warning {
        color: $warning;
        background-color: rgba($warning, 0.2);
        svg {
          fill: $warning;
        }
      }
    }
    .label {
      font-weight: bold;
      margin-left: 1em;

      .caption {
        font-size: 0.75rem;
        font-weight: normal;
        margin: 0.5em 0;
      }

      button {
        margin-top: 0.25em;
      }
    }
  }
}
::v-deep .warning {
  color: $warning;
}
</style>
