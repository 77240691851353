<template>
  <div>
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1>{{ label }}</h1>
      <div v-if="editing">
        <el-button @click="cancel">
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="save"
        >
          Save
        </el-button>
      </div>
      <el-button
        v-else
        type="primary"
        size="medium"
        icon="el-icon-edit-outline"
        @click="editing = true"
      >
        {{ buttonLabel }}
      </el-button>
    </el-row>
    <el-row class="editor-container">
      <text-editor
        v-if="editorData || editing"
        v-model="editorData"
        :editing="editing"
      />
      <div v-else class="empty">
        There are no notes saved for this company. To add a note, select the edit button.
      </div>
    </el-row>
  </div>
</template>

<script>
import TextEditor from '@/components/forms/TextEditor'

export default {
  name: 'CompanyNotesForm',
  components: {
    'text-editor': TextEditor
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: 'Edit'
    },
    notes: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      editing: false,
      editorData: ''
    }
  },
  computed: {
    dataModified () {
      return this.notes !== this.editorData
    }
  },
  watch: {
    notes (newNotes) {
      this.editorData = JSON.parse(JSON.stringify(newNotes))
    }
  },
  created () {
    window.addEventListener('beforeunload', this.handleUnload)
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.handleUnload)
  },
  methods: {
    /**
     * Handler for when the save button is clicked
     */
    save () {
      this.$emit('save', this.editorData)
      this.editing = false
    },
    /**
     * Handler for when the cancel button is clicked
     */
    cancel () {
      this.editing = false
      this.editorData = JSON.parse(JSON.stringify(this.notes))
    },
    /**
     * onbeforeunload handler, warns the user when they have have
     * modified data but not saved.
     *
     * @param {object} event
     * @returns {string|null} Message to display to user (ignored by all modern browsers), or null to cancel
     */
    handleUnload (event) {
      if (this.dataModified) {
        const msg = 'Data on this page may not be saved. Continue?'
        event.returnValue = msg
        return msg
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}
.editor-container {
  margin: 1em 0;

  .empty {
    font-style: italic;
    color: #bbb; // $--font-color-disabled-base
  }
}
</style>
