<template>
  <!-- External Link -->
  <el-link
    v-if="href"
    v-bind="$attrs"
    :href="href"
    :type="type"
  >
    <slot />
  </el-link>

  <!-- Interal Link -->
  <router-link
    v-else
    v-bind="$attrs"
    :to="to"
    class="el-link is-underline"
    :class="typeClass"
  >
    <span class="el-link--inner">
      <slot />
    </span>
  </router-link>
</template>

<script>
/**
 * Wrapper component for both el-link (external links) and router-link (internal links).
 * This is so 'links' in general have the same look and feel
 * regardless of internal or external.
 *
 * e.g.
 * Internal Link
 * <link-button
 *    :to="{ name: 'CompanyOverview', params: { cid: scope.row.company.id }}"
 *    target="_blank"
 *    type="primary"
 * >
 *    {{ scope.row.company.human_name }}
 * </link-button>
 *
 * External Link
 * <link-button
 *    href="https://google.ca"
 *    target="_blank"
 *    type="primary"
 * >
 *    Google
 * </link-button>
 */
export default {
  name: 'LinkButton',
  props: {
    to: {
      type: Object,
      default: () => ({ name: 'Root' })
    },
    href: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    typeClass () {
      switch (this.type) {
        case 'primary':
          return 'el-link--primary'
        case 'success':
          return 'el-link--success'
        case 'warning':
          return 'el-link--warning'
        case 'danger':
          return 'el-link--danger'
        case 'info':
          return 'el-link--info'
        default:
          return 'el-link--default'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-link {
  ::v-deep .el-link--inner {
    line-height: normal;
  }
}
</style>
